import { concat, flatMap, omit } from 'lodash';

// TODO: Update mob list as needed
// Source: https://game8.co/games/Genshin-Impact/archives/301850
const MOB_LIST = {
  Slimes: [
    'Pyro Slime',
    'Large Pyro Slime',
    'Cryo Slime',
    'Large Cryo Slime',
    'Hydro Slime',
    'Large Hydro Slime',
    'Electro Slime',
    'Large Electro Slime',
    'Geo Slime',
    'Large Geo Slime',
    'Dendro Slime',
    'Large Dendro Slime',
    'Anemo Slime',
    'Large Anemo Slime',
    'Mutant Electro Slime'
  ],
  Hilichurls: [
    'Pyro Hilichurl Shooter',
    'Stonehide Lawachurl',
    'Rock Shield Hilichurl Guard',
    'Hilichurl Shooter',
    'Hilichurl Fighter',
    'Hilichurl Berserker',
    'Hilichurl Grenadier',
    'Cryo Hilichurl Shooter',
    'Wooden Shield Hilichurl Guard',
    'Electro Hilichurl Shooter',
    'Geo Samachurl',
    'Hydro Samachurl',
    'Dendro Samachurl',
    'Anemo Samachurl',
    'Blazing Axe Mitachurl',
    'Rock Shieldwall Mitachurl',
    'Wooden Shield Mitachurl',
    'Hilichurl Chieftain',
    'Unusual Hilichurl',
    'Frostarm Lawachurl',
    'Cryo Samachurl',
    'Ice Shield Hilichurl Guard',
    'Cryo Hilichurl Grenadier',
    'Ice Shieldwall Mitachurl',
    'Hilichurl',
    'Electro Hilichurl Grenadier',
    'Thunderhelm Lawachurl',
    'Electro Samachurl',
    'Crackling Axe Mitachurl',
    'Anemo Hilichurl Rogue',
    'Hydro Hilichurl Rogue'
  ],
  'The Abyss': [
    'Pyro Abyss Mage',
    'Cryo Abyss Mage',
    'Hydro Abyss Mage',
    'Abyss Herald: Wicked Torrents',
    'Abyss Lector: Violet Lightning',
    'Electro Abyss Mage',
    'Abyss Lector: Fathomless Flames'
  ],
  Rifthounds: [
    'Rockfond Rifthound Whelp',
    'Rockfond Rifthound',
    'Thundercraven Rifthound Whelp',
    'Thundercraven Rifthound'
  ],
  'Black Serpent Knight and Shadowy Husks': [
    'Shadowy Husk: Standard Bearer',
    'Shadowy Husk: Line Breaker',
    'Shadowy Husk: Defender',
    'Black Serpent Knight: Windcutter'
  ],
  'Treasure Hoarders': [
    'Treasure Hoarders - Pyro Potioneer',
    'Treasure Hoarders - Seaman',
    'Treasure Hoarders - Marksman',
    'Treasure Hoarders - Handyman',
    'Treasure Hoarders - Hydro Potioneer',
    'Treasure Hoarders - Scout',
    'Treasure Hoarders - Gravedigger',
    'Treasure Hoarders - Cryo Potioneer',
    'Treasure Hoarders - Crusher',
    'Treasure Hoarders - Electro Potioneer',
    'Treasure Hoarders - Pugilist'
  ],
  Nobushi: [
    'Kairagi: Dancing Thunder',
    'Kairagi: Fiery Might',
    'Nobushi: Kikouban',
    'Nobushi: Jintouban',
    'Nobushi: Hitsukeban'
  ],
  Fatui: [
    'Fatui Pyro Agent',
    'Fatui Skirmisher (Cryo-Gunner Legionnaire)',
    'Fatui Skirmisher (Hydro-Gunner Legionnaire)',
    'Fatui Skirmisher (Electro-Hammer Vanguard)',
    'Fatui Skirmisher (Pyro-Slinger Bracer)',
    'Fatui Skirmisher (Anemoboxer Vanguard)',
    'Fatui Electro Cicin Mage',
    'Fatui Skirmisher (Geochanter Bracer)',
    'Fatui Cryo Cicin Mage',
    'Mirror Maiden'
  ],
  Fungi: [
    'Floating Hydro Fungus',
    'Winged Cryoshroom',
    'Winged Dendroshroom',
    'Grounded Hydroshroom',
    'Stretchy Geo Fungus',
    'Stretchy Anemo Fungus',
    'Stretchy Pyro Fungus',
    'Whirling Cryo Fungus',
    'Whirling Electro Fungus',
    'Floating Dendro Fungus'
  ],
  Eremites: [
    'Eremite - Daythunder',
    'Eremite - Desert Clearwater',
    'Eremite - Linebreaker',
    'Eremite - Ravenbeak Halberdier',
    'Eremite - Sunfrost',
    'Eremite - Sword Dancer',
    'Eremite - Crossbow',
    'Eremite - Axe Vanguard',
    'Eremite - Galehunter',
    'Eremite - Stone Enchanter'
  ],
  Automatons: [
    'Ruin Guard',
    'Ruin Hunter',
    'Ruin Grader',
    'Ruin Scout',
    'Ruin Defender',
    'Ruin Destroyer',
    'Ruin Cruiser',
    'Ruin Serpent',
    'Ruin Drake - Skywatch',
    'Ruin Drake - Earthguard'
  ],
  'Primal Constructs': [
    'Primal Construct - Repulsor',
    'Primal Construct - Reshaper',
    'Primal Construct Prospector'
  ],
  'Consecrated Beasts': [
    'Consecrated Flying Serpent',
    'Consecrated Scorpion',
    'Consecrated Red Vulture',
    'Consecrated Horned Crocodile',
    'Consecrated Fanged Beast'
  ],
  Vishaps: [
    'Geovishap Hatchling',
    'Geovishap',
    'Bolteater Bathysmal Vishap',
    'Rimebiter Bathysmal Vishap',
    'Primordial Bathysmal Vishap'
  ],
  Whopperflowers: [
    'Cryo Whopperflower',
    'Pyro Whopperflower',
    'Electro Whopperflower'
  ],
  Specters: [
    'Hydro Specter',
    'Geo Specter',
    'Anemo Specter',
    'Pyro Specter',
    'Cryo Specter',
    'Electro Specter',
    'Dendro Specter'
  ],
  Cicins: ['Cryo Cicin', 'Hydro Cicin', 'Electro Cicin'],
  'Other Lifeforms': [
    'Eye of the Storm',
    'Hydro Mimic Boar',
    'Hydro Mimic Crab',
    'Hydro Mimic Crane',
    'Hydro Mimic Ferret',
    'Hydro Mimic Finch',
    'Hydro Mimic Frog',
    'Hydro Mimic Mallard',
    'Hydro Mimic Raptor',
    'Maguu Kenki - Galloping Frost',
    'Maguu Kenki - Lone Gale'
  ],
  'Weekly Bosses': [
    'Stormterror Dvalin',
    'Lupus Boreas',
    'Childe',
    'Azhdaha',
    'La Signora',
    'Raiden Shogun',
    'Scaramouche Boss',
    "Guardian of Apep's Oasis"
  ],
  'World Bosses': {
    Mondstadt: [
      'Anemo Hypostasis',
      'Cryo Hypostasis',
      'Cryo Regisvine',
      'Electro Hypostasis'
    ],
    Liyue: [
      'Geo Hypostasis',
      'Oceanid',
      'Primo Geovishap',
      'Pyro Regisvine',
      'Ruin Serpent'
    ],
    Inazuma: [
      'Bathysmal Vishap Herd',
      'Golden Wolflord',
      'Hydro Hypostasis',
      'Maguu Kenki',
      'Perpetual Mechanical Array',
      'Pyro Hypostasis',
      'Thunder Manifestation'
    ],
      Sumeru: [
        'Aeonblight Drake',
        'Algorithm of Semi-Intransient Matrix of Overseer Network',
        'Dendro Hypostasis',
        'Electro Regisvine',
        'Iniquitous Baptist',
        'Jadeplume Terrorshroom',
        'Setekh Wenut'
      ]
  }
};

const WEEKLY_BOSSES = MOB_LIST['Weekly Bosses'];
const WORLD_BOSSES = flatMap(MOB_LIST['World Bosses']);
const OVERWORLD_MOBS = flatMap(
  omit(MOB_LIST, ['Weekly Bosses', 'World Bosses'])
);
const ALL_MOBS = concat(WEEKLY_BOSSES, WORLD_BOSSES, OVERWORLD_MOBS);

export default {
  ALL_MOBS,
  OVERWORLD_MOBS,
  WEEKLY_BOSSES,
  WORLD_BOSSES
};
